// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

import { AuthProvider } from './contexts/FirebaseContext';
import { ConfigProvider } from './contexts/ConfigContext';
//
import App from './App';

// ----------------------------------------------------------------------

ReactDOM.render(
  <AuthProvider>
    <ConfigProvider>
      <HelmetProvider>
        <SettingsProvider>
          
            <CollapseDrawerProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CollapseDrawerProvider>
          
        </SettingsProvider>
      </HelmetProvider>
    </ConfigProvider>
  </AuthProvider>,
  document.getElementById('root')
);
