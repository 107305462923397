import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';


// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    
    <Box
        sx={{
          py: 5,
          textAlign: 'center',
          position: 'relative',
          bgcolor: 'background.default',
        }}
      >
        <Container>
          <Logo sx={{ mb: 1, mx: 'auto' }} />

          <Typography variant="caption" component="p">
            © All rights reserved, <Link href="https://enchantedpixel.com/">EnchantedPixel.com</Link>
            <br />
             <Link href="/privacy">Privacy Policy</Link>.
          </Typography>
        </Container>
      </Box> 

  );
}
