import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

import useAuth from '../hooks/useAuth';

import EnchantedLogoSmall from '../assets/EnchantedLogoSmall.png';

// ----------------------------------------------------------------------

LogoSmall.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function LogoSmall({ disabledLink = false, sx }) {
  const logoSmall = (
    <Box sx={{ width: 64, height: 64, ...sx }} component="img" src={EnchantedLogoSmall} />
  );

  if (disabledLink) {
    return <>{logoSmall}</>;
  }

  return <RouterLink to="/">{logoSmall}</RouterLink>;
    
}
