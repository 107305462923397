import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'privacy', element: <Privacy />},
      ],
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/home" replace />, index: true },
        { path: 'home', element: <Dashboard /> },
        { path: 'leaderboards', element: <Leaderboards />},
        { path: 'messages', element: <Messages /> },
      ],
    },
    {
      path: 'dashboard/users',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Users /> },
        { path: ':userId/edit', element: <UserEdit /> },
      ],
    },
    {
      path: 'dashboard/events',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Events /> },
        { path: ':eventId/reports', element: <EventReports /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/Login')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/admin/Dashboard')));
const Leaderboards = Loadable(lazy(() => import('../pages/admin/Leaderboards')));
const Messages = Loadable(lazy(() => import('../pages/admin/Messages')));

const Events = Loadable(lazy(() => import('../pages/admin/Events')));
const EventReports = Loadable(lazy(() => import('../pages/admin/EventReports')));


const Users = Loadable(lazy(() => import('../pages/admin/Users')));
const UserEdit = Loadable(lazy(() => import('../pages/admin/UserEdit')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const Privacy = Loadable(lazy(() => import('../pages/Privacy')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));